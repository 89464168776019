<template>
  <div class="bg-white p-12">
    <div class="pb-12 flex justify-between">
      <div :class="classes.header">
        Select your theme template<span
          class="ml-1 text-3xl text-red-600"
          v-show="isRequired"
          >*
        </span>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-y-8 md:grid-cols-3 grid-rows-1">
      <div class="mr-4" v-for="item in templates" :key="item">
        <div
          :class="`cursor-pointer ${
            value == item.stylingId ? 'border-4 rounded-3xl' : ''
          }`"
          @click="update(item)"
          class="flex flex-col justify-center align-center items-center"
        >
          <img
            :src="item.templatePreview"
            alt="template-preview-image"
            class="w-full object-contain"
          />
        </div>
        <div :class="classes.header + ' text-center'">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    },
    templates: {
      type: Array,
      default: () => []
    },
    classes: {
      type: Object,
      default: () => {}
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    update(item) {
      this.$emit('update', item);
    }
  }
};
</script>
