import {
  get,
  update,
  includes,
  chain,
  random,
  isNil,
  isEmpty,
  uniqueId,
  forEach,
  map,
  replace,
  find
} from 'lodash';
import { convertURLToImagePreview } from '~/functions/conversion-functions';
import { useStore } from 'vuex';
import { computed, reactive, ref, inject } from 'vue';
import dateFormat from 'dateformat';
import { formatImageUrl } from '~/functions/utils-functions';
import manageUsersApi from '~/api/manageUsers';
import userState from '~/store/state-managers/user';
import { $vfm } from 'vue-final-modal';
import { convertSelfServiceSchemeToDBProfile } from '~/functions/conversion-functions';

const httpProtocolRegEx = /^(http|https):\/\//i;
const enforceHttpsProtocol = (linksObject) => {
  if (!linksObject.url) {
    return linksObject;
  }
  if (httpProtocolRegEx.test(linksObject.url)) {
    return linksObject;
  }
  linksObject.url = `https://${linksObject.url}`;
  return linksObject;
};
export default function (
  profile,
  selfServiceApi,
  defaultThemeList,
  featureFlagHandler,
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  permissionsHandler,
  config = {
    fetchNewProfileInstance: () => {},
    autosave: false
  }
) {
  // CONSTANTS
  const toast = inject('toast');
  const store = useStore();
  let debounce = null;
  const dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: 'ghost'
  };

  // WRAPPERS
  const { getProfileId } = userState();

  // REFS
  const imagesLoading = ref([]);
  const firstTimeSaving = ref(true);

  const updatePreview = ref(0);
  const showingPreview = ref(false);
  const profileAlias = reactive({
    test: '',
    approved: false,
    testing: false
  });
  // #reactivity problems
  const croppingImage = ref({ url: '', id: '', key: '' });
  const savingProfile = reactive({
    loading: false,
    subscribing: false,
    publishing: false
  });
  const loadAccordianSections = () => {
    const lsSect = sessionStorage.getItem('accordionSections');
    let sectionsObj = {
      'personal-details': true,
      appearance: false,
      themes: false,
      branding: false,
      links: false,
      'main-action-button': false,
      testimonials: false,
      offers: false,
      videos: false,
      tools: false,
      'social-media': false,
      'contact-form': false,
      'footer-disclaimer': false,
      referral_message: false,
      'qr-code': false,
      infoSections: false
    };
    try {
      if (lsSect) {
        sectionsObj = JSON.parse(lsSect);
      }
    } catch (error) {
      console.warn('localStorage stored undefined');
      sessionStorage.removeItem('accordionSections');
    }
    return sectionsObj;
  };
  const sectionAccordions = reactive(loadAccordianSections());
  const firstBuild = ref(true);
  const imageCropper = ref(null); // This needs to not be a ref
  const imageGalleryItem = ref({});
  const popupItem = ref({});

  const loadingImageGalleryItem = ref(false);
  const publishProgress = ref(0);
  const logoPhotoLoading = ref(false);
  const profilePhotoLoading = ref(false);
  const backgroundPhotoLoading = ref(false);
  const bannersPhotoLoading = ref({
    bannerMobile: false,
    bannerDesktop: false
  });
  // #reactivity problems
  sectionHandler.onChange((name) =>
    debounceAutoSave({
      target: { name }
    })
  );

  linksArrayHandler.addOnChange((links) =>
    debounceAutoSave({
      target: {
        name: 'links',
        value: links
      }
    })
  );
  socialMediaArrayHandler.addOnChange((socialLinks) =>
    debounceAutoSave({
      target: {
        name: 'socialLinks',
        value: socialLinks
      }
    })
  );
  // COMPUTED
  const companyDomainAliasExample = computed(() => {
    let link = 'https://';
    if (profile.company?.customDomain) {
      link += `${profile.company?.customDomainUrl}/`;
    } else {
      if (profile.company?.name) {
        link += `${profile.company?.name}.profileme.app/`;
      } else {
        link += `[company name].profileme.app/`;
      }
    }
    link += '[your website link]';
    return 'Example: ' + link;
  });
  const isMaster = computed(() => {
    return profile.isMaster;
  });
  const profileId = computed(getProfileId);
  const isDesktopTemplate = computed(() => {
    return includes(getValueOrDefaultToMaster('templateLayout'), 'desktop');
  });

  const allowPublish = () => {
    if (profile.isMaster) {
      return true;
    }
    return permissionsHandler.requiredForPublish(profile).length === 0;
  };

  // GETTERS

  const getUser = () => {
    return get(store, 'state.auth.user', '');
  };
  const getJwt = () => {
    return sessionStorage.getItem('_token');
  };
  const getCompany = computed(() => {
    return get(profile, 'company', undefined);
  });
  const getImageGalleryLimit = computed(() => {
    return get(profile, 'limits.imageGallery', 5);
  });
  const allowTemplateEdit = computed(() => {
    return (
      ((!profile.hasMaster || profile.isMaster) &&
        profile.stylingId &&
        !idIsATheme.value &&
        featureFlagHandler.isSupervisor) ||
      featureFlagHandler.isSuperAdmin
    );
  });
  const getProfileUrl = () => {
    return `https://${profile.domain?.domainName || ''}/${
      profile.profileAlias || ''
    }`;
  };
  // METHODS
  function hasAlias() {
    return (
      get(profile, 'profileAlias', '')?.trim() ||
      (profileAlias.test?.trim() && profileAlias.approved)
    );
  }
  function closeModal(name) {
    $vfm.hide(name);
  }
  function openModal(name) {
    $vfm.show(name);
  }
  function onlyLettersAndSpaces(str) {
    return /^[A-Za-z0-9 _-]*[A-Za-z0-9 _-][A-Za-z0-9 _-]*$/.test(str);
  }
  function isLetter(e) {
    let char = e.key.toString().toLowerCase(); // Get the character
    const test = profileAlias.test + char;
    if (test.length > 30 && e.code != 'Backspace') {
      return e.preventDefault();
    }
    if (
      onlyLettersAndSpaces(test) ||
      e.code == 'Backspace' ||
      e.code == 'Space'
    ) {
      if (
        (e.key == ' ' || e.key == '-' || e.key == '_') &&
        (profileAlias.test.slice(-1) == '_' ||
          profileAlias.test.slice(-1) == '-')
      ) {
        return e.preventDefault();
      }
      return true;
      // Match with regex
    } else e.preventDefault(); // If not match, don't add to input text
  }
  async function checkProfileAlias(e) {
    const { value } = e.target;
    let clean = value?.split(' ')?.join('-');

    clean = clean.replace('--', '-');
    clean = clean.replace('__', '_');
    clean = clean.replace('-_', '-');
    clean = clean.replace('_-', '_');
    profileAlias.test = clean?.toLowerCase();

    if (profileAlias.test?.trim()) {
      profileAlias.testing = true;
      try {
        const test = await selfServiceApi.testAlias(clean?.toLowerCase());
        if (test.success) {
          if (test.message) {
            profileAlias.testing = false;
            profileAlias.approved = true;
          } else {
            profileAlias.testing = false;
            profileAlias.approved = false;
          }
        } else {
          toast({ success: true, message: test.message });
        }
      } catch (err) {
        toast({ success: false, message: err.message });
      }
    }
  }

  async function buildProfile(callback = () => {}) {
    if (profile.domain?.siteId) {
      firstBuild.value = false;
    } else {
      firstBuild.value = true;
    }
    savingProfile.subscribing = true;
    const build = await selfServiceApi.buildProfile(getProfileId());
    if (build.success) {
      if (isMaster.value) {
        callback(true);
      } else {
        showBuildingProfile();
      }
      profile.domain = build.site;
      profile.publishedAt = new Date().toISOString();
      config?.fetchNewProfileInstance({
        profileId: getProfileId(),
        noLoading: true
      });
    } else {
      toast({ success: false, message: 'Publishing Error' });
      callback(false);
    }
    savingProfile.subscribing = false;
  }
  const saveButtonTitle = computed(() => {
    if (savingProfile.loading) {
      return 'Saving...';
    }
    if (isMaster.value) {
      return 'Apply';
    }
    return 'Submit';
  });
  async function publish(consent = false) {
    savingProfile.subscribing = true;
    if (!allowPublish()) {
      savingProfile.subscribing = false;
      return openModal('modal-publish-warning');
    } else {
      // manage consent
      if (profile.enableConsent && consent) {
        return openModal('modal-publish-consent');
      }
      closeModal('modal-publish-consent');

      // manage alias
      if (!hasAlias()) {
        savingProfile.subscribing = false;
        return openModal('modal-alias');
      }
      closeModal('modal-alias');
      if (profileAlias.test && profileAlias.approved) {
        const setAlias = await selfServiceApi.setAlias(
          getProfileId(),
          profileAlias.test
        );
        if (setAlias.success) {
          profile.profileAlias = profileAlias.test;
        } else {
          return (savingProfile.subscribing = false);
        }
      }

      // manage build
      if (hasAlias()) {
        if (
          featureFlagHandler.allowedFeaturePerProfile(getCompany.value)[
            'approvals'
          ]
        ) {
          requestApproval();
        } else {
          buildProfile();
        }
      } else {
        return openModal('modal-alias');
      }
    }
  }
  const updateMaster = () => {
    closeModal('update-master');
    savingProfile.loading = true;
    saveDetails(profile, false, () => {
      buildProfile(() => {
        toast({
          success: true,
          message: 'Your changes has been saved!'
        });
      });
    });
  };
  const updateAllLinkedProfiles = async () => {
    closeModal('update-master');
    savingProfile.loading = true;

    const saved = await selfServiceApi.saveProfileToDB(profile, {
      isSupervisor: featureFlagHandler.isSupervisor,
      isSuperAdmin: featureFlagHandler.isSuperAdmin,
      userId: getUser().id,
      userEmail: getUser().email
    });
    if (!saved.success) {
      savingProfile.loading = false;
      toast({ success: false, message: saved.message });
      return;
    }
    buildProfile(async (success) => {
      if (success) {
        const updated = await selfServiceApi.updateMasterChildren(
          profileId.value
        );
        if (updated.success) {
          savingProfile.loading = false;
          toast({
            success: true,
            message:
              'Template has been saved and all published profiles have been updated successfully!'
          });
          return;
        }
        savingProfile.loading = false;
        toast({ success: false, message: saved.message });
      } else {
        savingProfile.loading = false;
      }
    });
    return;
  };
  async function requestApproval() {
    const api = manageUsersApi(getJwt());
    savingProfile.subscribing = true;
    if (!featureFlagHandler.isSupervisor && !featureFlagHandler.isSuperAdmin) {
      const build = await api.createApproval({
        profileId: profile.profileId,
        companyId: profile.company?.id,
        update: profile.pendingApproval
      });

      if (build.success) {
        profile.pendingApproval = build.id;
        toast({
          success: true,
          message:
            'Your digital profile data is being submitted for approval. You will be alerted via email once approved.'
        });
      } else {
        toast({ success: false, message: 'Approval Request Error' });
      }
    } else {
      if (
        profile.pendingApproval &&
        (featureFlagHandler.isSupervisor || featureFlagHandler.isSuperAdmin)
      ) {
        await api.updateApproval({
          id: profile.pendingApproval,
          answer: true,
          rebuild: false
        });
        return buildProfile();
      } else if (
        !profile.pendingApproval &&
        (featureFlagHandler.isSupervisor || featureFlagHandler.isSuperAdmin)
      ) {
        return buildProfile();
      } else {
        toast({
          success: true,
          message:
            'Your digital profile data is being submitted for approval. You will be alerted via email once approved.'
        });
      }
    }
    savingProfile.subscribing = false;
  }
  // Manage Content
  function createObjectURL(object) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }
  // Adders
  function addAnotherLink(type = 'url') {
    const item = {
      title: '',
      url: '',
      key: new Date().getTime().toString(),
      icon: '',
      source: 'self',
      enabled: true,
      contactType: type,
      id: new Date().getTime()
    };

    if (type == 'modal') {
      item.url = uniqueId('text' + '-' + Date.now());
      popupsArrayHandler.addToChild({
        type: 'text',
        title: '',
        isModal: true,
        key: item.url,
        description: ''
      });
    }

    linksArrayHandler.addToChild(item);

    closeModal('modal-links');
    debounceAutoSave();
  }
  function addAnotherSocial(e) {
    socialMediaArrayHandler.addToChild({
      title: e?.title,
      url: '',
      key: e?.title?.toLowerCase(),
      icon: e.icon,
      source: 'self',
      enabled: true,
      id: new Date().getTime()
    });
    debounceAutoSave();
  }
  function addAnotherVideo(type, key) {
    sectionHandler.addToChild(
      {
        title: '',
        url: '',
        enabled: true,
        source: 'self',
        key: Date.now().toString(),
        type: key
      },
      type,
      key
    );
    debounceAutoSave();
  }

  const addImageByType = (image) => {
    handleImageGalleryItem(image, (newImage) => {
      if (image.id)
        sectionHandler.updateWholeChildAtId(
          newImage,
          newImage.key,
          'imageGallery',
          image.type
        );
      else sectionHandler.addToChild(newImage, 'imageGallery', image.type);
    });
  };
  const addTestimonials = (key) => {
    const defaultTestimonial = () => ({
      title: '',
      review: '',
      author: '',
      date: dateFormat(Date.now(), 'dS mmmm yyyy'),
      rating: 0,
      key: random(100000) + '' + Date.now(),
      type: key
    });
    profile.testimonials[profile.testimonials.length] = defaultTestimonial();
    debounceAutoSave();
  };
  // Removers
  const removeImageLoading = (url) => {
    imagesLoading.value = imagesLoading.value.filter((e) => e !== url);
  };
  function removeArrayItemByKey(path, key) {
    profile[path] = profile[path].filter((i) => i.key !== key);
    debounceAutoSave();
  }

  function openCropper() {
    imageCropper.value.openDialog();
  }

  async function saveDetails(newProfile, silent = false, callback = () => {}) {
    if (!silent) {
      savingProfile.loading = true;
    }
    // sanitizeHtml removes the iframe and oembed tags breaking media links
    // newProfile.aboutMe = sanitizeHtml(newProfile.aboutMe);
    const saved = await selfServiceApi.saveProfileToDB(newProfile, {
      isSupervisor: featureFlagHandler.isSupervisor,
      isSuperAdmin: featureFlagHandler.isSuperAdmin,
      userId: getUser().id,
      userEmail: getUser().email
    });

    if (allowTemplateEdit.value && newProfile.stylingId) {
      await selfServiceApi.updateStyleSheet(
        {
          id: newProfile.stylingId,
          cssPlug: newProfile.stylingTemplateCssPlug,
          layout: newProfile.templateLayout,
          fontFamily: newProfile.fontFamily
        },
        newProfile.stylingId
      );
    }
    if (!saved.success) {
      toast({ success: false, message: saved.message });
      savingProfile.loading = false;
      return;
    } else {
      if (config?.afterDetailsSaved) config?.afterDetailsSaved(newProfile);
      profile.companyId = saved?.companyId;
      store.commit('auth/setProfileId', saved.message);
    }
    savingProfile.loading = false;
    if (!silent)
      if (!saved.hideNotification) {
        if (isMaster.value) {
          callback(true);
        }
        if (firstTimeSaving.value) {
          firstTimeSaving.value = false;
          toast({
            success: true,
            message: isMaster.value
              ? 'Your changes has been saved. All new profiles created with this template will have the latest values'
              : `Your changes have been saved. Continue making changes, or submit your profile if you are happy with the updates made.`
          });
        }
        return;
      }
  }
  const focusElement = (el) => {
    const end = document.getElementById(el).value.length;
    document.getElementById(el).setSelectionRange(end, end);
    document.getElementById(el).focus();
  };
  const isImageUploading = (url = 'placeholder') => {
    return imagesLoading.value.filter((e) => e === url)[0] ? true : false;
  };
  function switchBannerPhotoLoad(bannerType, load = true) {
    switch (bannerType) {
      case 'mobileBanner':
        bannersPhotoLoading.value.bannerMobile = load;
        break;
      case 'desktopBanner':
        bannersPhotoLoading.value.bannerDesktop = load;
        break;
      case 'logo':
        logoPhotoLoading.value = load;
        break;
      case 'profilePhoto':
        profilePhotoLoading.value = load;
        break;
      case 'banner':
        backgroundPhotoLoading.value = load;
        break;
      default:
        break;
    }
  }
  function fetchFileOgImage(e) {
    if (!e?.target?.files?.[0]) {
      return {
        file: e.url,
        ogImage: e.og
      };
    }
    const _file = createObjectURL(e.target.files[0]);
    return {
      file: _file,
      ogImage: _file
    };
  }

  async function handleImageUpload(e, path) {
    const imageLoadingUrl = profile[path]?.url || 'placeholder';
    imagesLoading.value.push(imageLoadingUrl);
    try {
      switchBannerPhotoLoad(path);
      const { file, ogImage } = fetchFileOgImage(e);
      const _uploadImage = selfServiceApi.uploadImage;
      const _deleteImage = selfServiceApi.deleteImage;
      if (profile[path].id && !isMaster.value) {
        await _deleteImage(profile[path].id);
      }
      let attemptUpload = true;
      let cloudinaryImage = null;
      if (!file) {
        profile[path] = {
          url: '',
          id: '',
          key: path
        };
        attemptUpload = false;
      }
      if (attemptUpload) {
        const { success, message } = await _uploadImage({ url: file }, true);
        if (!success) {
          toast({ success: false, message: 'Error uploading image' });
          return;
        }
        cloudinaryImage = {
          og: ogImage,
          url: message.url,
          id: message.id,
          source: 'self',
          key: Date.now().toString()
        };
        profile[path] = cloudinaryImage;
      }
      if (includes(['mobileBanner', 'desktopBanner'], path)) {
        const someBanner = profile.banners.find((b) => b.type == path);
        let updatedBanner = undefined;
        if (someBanner) {
          updatedBanner = await selfServiceApi.updateProfileBannerImage(
            someBanner,
            cloudinaryImage
          );
        } else {
          updatedBanner = await selfServiceApi.createNewProfileBanner(
            profile.profileId,
            cloudinaryImage,
            path
          );
        }
        if (updatedBanner) {
          if (isEmpty(profile.banners)) {
            profile.banners = [updatedBanner];
          } else {
            const bannerIndex = profile.banners.findIndex(
              (el) => el.type === path
            );
            if (bannerIndex >= 0) {
              profile.banners[bannerIndex] = updatedBanner;
            } else {
              profile.banners[profile.banners.length] = updatedBanner;
            }
          }
          if (updatedBanner.banner && !isEmpty(updatedBanner.banner.slider)) {
            profile[path] = {
              url: updatedBanner.banner.slider[0].url,
              id: updatedBanner.banner.slider[0].id,
              key: path
            };
          }
        }
      } else {
        profile[path] = cloudinaryImage;
      }
    } catch (error) {
      console.warn(error);
      throw error;
    } finally {
      removeImageLoading(imageLoadingUrl);
      switchBannerPhotoLoad(path, false);
      debounceAutoSave();
    }
  }

  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  async function downloadQR() {
    if (profile.qrcode?.url) {
      const a = document.createElement('a');
      a.href = await toDataURL(formatImageUrl(profile.qrcode.url));
      a.download =
        'qrcode.' +
        chain(profile.qrcode.url)
          .split('.')
          .last()
          .thru((fileType) => fileType || 'jpg')
          .value();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
  function preview() {
    return (showingPreview.value = true);
  }
  function scrollToRef(ref, section) {
    forEach(sectionAccordions, (val, key) => {
      sectionAccordions[key] = key == section ? true : false;
    });
    setTimeout(() => {
      location.hash = '#';
      location.hash = ref;
      closeModal('modal-publish-warning');
    }, 500);
  }
  function showBuildingProfile(callback = () => {}) {
    openModal('modal-publish-loading');
    let publishInterval = null;
    let seconds = 0;
    const maxSeconds = firstBuild.value ? 30 : 5;

    publishInterval = setInterval(() => {
      if (seconds === maxSeconds) {
        clearInterval(publishInterval);
        closeModal('modal-publish-loading');
        callback();
      } else {
        seconds++;
        publishProgress.value = Math.ceil((seconds / maxSeconds) * 100);
      }
    }, 1000);
  }
  const lightLogo = computed(() => store.state.whiteLabel.lightLogo);
  function changeIconStyle(style) {
    profile.iconStyle = style;
    debounceAutoSave();
  }
  function formatIcon(icon) {
    return icon?.replace('{type}', profile.iconStyle || 'regular') || icon;
  }
  function getDefaultFromMaster(path, defaultPlaceholder = '') {
    if (!profile?.masterProfile) return defaultPlaceholder;
    return chain(profile.masterProfile).get(path, defaultPlaceholder).value();
  }
  function getValueOrDefaultToMaster(path, defaultPlaceholder = '') {
    return !isEmpty(get(profile, path)) && !isNil(get(profile, path))
      ? get(profile, path)
      : getDefaultFromMaster(path, defaultPlaceholder);
  }
  function resetImageGalleryItem() {
    imageGalleryItem.value = {};
  }
  function editPicture(picture, type, editable) {
    if (editable) {
      imageGalleryItem.value = {
        ...picture,
        type
      };
      openModal('modal-photo-gallery');
    }
  }
  const updateProfileValueEvent = (e) => {
    e.stopPropagation();
    update(profile, e.target.name, () => e.target.value);
  };

  async function handleImageGalleryItem(imageGalleryItem, callback) {
    if (
      imageGalleryItem.expireAt &&
      new Date(imageGalleryItem.expireAt).getTime() < new Date()
    ) {
      toast({
        success: false,
        message: 'Expire date should be in the future'
      });
      return;
    }
    loadingImageGalleryItem.value = true;
    // New image to upload
    if (imageGalleryItem.file) {
      if (imageGalleryItem.image) {
        await selfServiceApi.deleteImage(imageGalleryItem.image);
      }
      const newImage = await selfServiceApi.uploadImage(
        { url: imageGalleryItem.imageUrl },
        true
      );
      // save image in profile
      if (newImage.success) {
        if (imageGalleryItem.id) {
          // Image hasn't been uploaded. only transformed
          callback({
            ...imageGalleryItem,
            id: newImage?.message?.id || imageGalleryItem.id,
            url: newImage?.message?.url || imageGalleryItem.url
          });
        } else {
          // Image has been uploaded
          callback({
            ...imageGalleryItem,
            id: newImage.message.id,
            url: newImage.message.url,
            enabled: true,
            key: uniqueId(Date.now())
          });
        }
        loadingImageGalleryItem.value = false;
        closeModal('modal-photo-gallery');
      } else {
        loadingImageGalleryItem.value = false;
        toast({ success: false, message: 'Error uploading image' });
      }
    } else {
      callback(imageGalleryItem);
      loadingImageGalleryItem.value = false;
      closeModal('modal-photo-gallery');
    }

    debounceAutoSave({
      target: { name: 'imageGallery' }
    });
  }

  const setValueToNull = (id) => {
    document.getElementById(id).value = null;
  };

  const debounceAutoSave = function (
    e = { target: { name: 'nothing', type: '' } },
    silent = false
  ) {
    const { name, value, checked, type } = e.target;
    if (!name) {
      return null;
    }
    if (
      name !== 'enable-contact-form' &&
      name !== 'profile-alias' &&
      name !== 'profile-image-input' &&
      name !== 'logo-input' &&
      name !== 'banner-image-input' &&
      name !== 'navigateToOffice-wrapper' &&
      !name.includes('image-gallery-input')
    ) {
      if (!chain(value).isUndefined().value()) {
        if (name == 'aboutmevideo') {
          if (!value.trim() && !getDefaultFromMaster('aboutMeVideo')?.trim()) {
            update(profile, 'aboutMeVideoEnabled', () => false);
          } else {
            update(profile, 'aboutMeVideoEnabled', () => true);
          }
        }
        update(profile, name, () => (type === 'checkbox' ? checked : value));
      }

      profile.imageGallery =
        sectionHandler.getItemWrappersByType('imageGallery');
      profile.videoGallery =
        sectionHandler.getItemWrappersByType('videoGallery');
      profile.infoSections = map(sectionHandler.state.infoItems, 'info');
      profile.infoPopups = popupsArrayHandler.childArray;
      profile.socialLinks =
        socialMediaArrayHandler.childArray.map(enforceHttpsProtocol);
      profile.links = linksArrayHandler.childArray;

      if (!silent && config?.autosave) {
        savingProfile.loading = true;
      }
      clearTimeout(debounce);
      updatePreview.value++;
      debounce = setTimeout(
        () => {
          if (config?.autosave) {
            saveDetails(profile, silent);
          }
        },
        silent ? 0 : 2000
      );
    }
  };

  const addSection = (type, title) => {
    sectionHandler.addToSection(
      {
        type,
        title,
        key: uniqueId(type + '-' + Date.now()),
        enabled: true
      },
      profile,
      profile.masterProfile
    );
    closeModal('modal-section');
  };

  const toggleElementClass = (currentClass, elementClass, toggleOn) => {
    const tempClass = currentClass || '';
    if (toggleOn) {
      if (!tempClass.includes(` ${elementClass}`))
        return tempClass + ` ${elementClass}`;
    } else return replace(/tempClass/g, ` ${elementClass}`, '');
  };

  const stylingTemplates = computed(() => {
    return (
      profile.availableThemes?.length
        ? profile.availableThemes
        : defaultThemeList
    ).map((item) => {
      return {
        stylingId: item.id,
        title: item.name,
        cssPlug: item.cssPlug,
        layout: item.layout,
        fontFamily: item.fontFamily,
        templatePreview: formatImageUrl(item.previewImage?.url || '')
      };
    });
  });

  const themes = computed(() => {
    return profile.availableThemes?.length
      ? profile.availableThemes
      : defaultThemeList;
  });

  function enableContactForm(checked) {
    if (checked) {
      if (
        profile.sendToMail?.trim() &&
        profile.contactForm.thankYouMessage?.trim()
      ) {
        profile.contactForm.enabled = true;
        debounceAutoSave();
      } else {
        toast({
          success: false,
          message: '"Send to email" and "Thank you message" required!'
        });
        profile.contactForm.enabled = false;
        profile.contactForm.enabled = false;
      }
    } else {
      profile.contactForm.enabled = false;
      debounceAutoSave();
    }
  }
  //#endregion
  async function uploadVideoGifOrCropImage(e, key) {
    try {
      const file = e.target.files[0];
      const ALLOWED_VIDEO_MIME_TYPES = ['video/mp4', 'video/quicktime'];
      if (file) {
        const extension = file.type;
        croppingImage.value.url = createObjectURL(file);
        croppingImage.value.key = key;
        croppingImage.value.og = createObjectURL(file);
        setValueToNull(key);
        if (
          extension == 'image/gif' ||
          ALLOWED_VIDEO_MIME_TYPES.includes(extension)
        ) {
          await handleImageUpload(croppingImage.value, key);
        } else if (/^image\//.test(extension)) {
          openCropper();
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
  const idIsATheme = computed(() =>
    find(stylingTemplates.value, { stylingId: profile.stylingId })
  );
  const isQrCodeUploaded = computed(() => {
    return profile.qrcode && profile.qrcode.url != '';
  });
  const domainAliasForQrCode = computed(() => {
    return `https://${
      profile.company?.customDomain
        ? `${profile.company?.customDomainUrl}`
        : profile.company?.name
        ? `${profile.company?.name}.profileme.app`
        : 'hello.profileme.app'
    }/${profile.profileAlias}`;
  });

  return {
    domainAliasForQrCode,
    isQrCodeUploaded,
    enableContactForm,
    uploadVideoGifOrCropImage,
    setValueToNull,
    updateProfileValueEvent,
    companyDomainAliasExample,
    openCropper,
    imageCropper,
    convertURLToImagePreview,
    sectionAccordions,
    // Testimonials
    addTestimonials,
    // Computed
    profileForPreview: computed(() => ({
      ...(profile?.fullProfile || {}),
      ...convertSelfServiceSchemeToDBProfile(profile, false)
    })),
    allowTemplateEdit,
    isDesktopTemplate,
    stylingTemplates,
    themes,
    idIsATheme,
    itemIsRequired: permissionsHandler.itemIsRequired,
    itemIsEditable: permissionsHandler.itemIsEditable,
    itemIsShown: permissionsHandler.itemIsShown,
    requiredForPublish: permissionsHandler.requiredForPublish,
    permissions: permissionsHandler.permissions,
    // Edit items in popup
    imageGalleryItem,
    popupItem,
    croppingImage,
    // methods
    toggleElementClass,
    addSection,
    downloadQR,
    getDefaultFromMaster,
    getValueOrDefaultToMaster,
    isLetter,
    profileId,
    formatImageUrl,
    createObjectURL,
    formatIcon,
    changeIconStyle,
    handleImageUpload,
    addImageByType,
    isSupervisor: featureFlagHandler.isSupervisor,
    isSuperAdmin: featureFlagHandler.isSuperAdmin,
    debounceAutoSave,
    addAnotherLink,
    addAnotherSocial,
    addAnotherVideo,
    removeArrayItemByKey,
    closeModal,
    preview,
    firstBuild,
    getProfileUrl,
    publish,
    checkProfileAlias,
    scrollToRef,
    openModal,
    resetImageGalleryItem,
    editPicture,
    isImageUploading,
    saveButtonTitle,
    profile,
    // variables
    updatePreview,
    lightLogo,
    allowPublish,
    dragOptions,
    getImageGalleryLimit,
    savingProfile,
    profilePhotoLoading,
    backgroundPhotoLoading,
    logoPhotoLoading,
    bannersPhotoLoading,
    getCompany,
    profileAlias,
    publishProgress,
    showingPreview,
    focusElement,
    updateMaster,
    loadingImageGalleryItem,
    isMaster,
    updateAllLinkedProfiles,
    selfServiceApi
  };
}
