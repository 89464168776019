<!-- eslint-disable no-unused-vars -->
<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue';
import { chain, uniqueId } from 'lodash';
import ColorPicker from 'commspace-colorpicker';
import pmButton from '~/components/ui/pm-button.vue';
import defaultImage from '~/assets/img/color-wheel.png';
import 'commspace-colorpicker/dist/style.css';

const props = defineProps({
  defaultColour: {
    type: Object,
    default: () => undefined
  },
  modelValue: [Object, String],
  name: String,
  title: String,
  right: { type: Boolean, default: false }
});
const hasColour = (checkMe) => {
  if (checkMe) {
    if (checkMe.css && checkMe.mode && Object.keys(checkMe.color).length > 0) {
      return true;
    }
  }
  return false;
};
const defaultCurrentColour = computed(() => {
  if (hasColour(props.defaultColour)) {
    return props.defaultColour?.css;
  }
  return `url(${defaultImage})`;
});
const currentColor = ref(
  props.modelValue || {
    color: {},
    css: '',
    mode: ''
  }
);
const showColorPicker = ref(false);
const showColorId = ref(uniqueId('color-'));
const emit = defineEmits(['update:modelValue', 'update', 'change']);
const mode = computed(() => currentColor.value.mode || 'solid');
const colorSelected = computed(() => {
  if (hasColour(props.modelValue)) {
    return props.modelValue;
  }
  return '';
});
const color = computed(
  () => currentColor.value.color || { r: 17, g: 75, b: 138, a: 1 }
);
//17,75,138
const gradients = computed(() =>
  mode.value == 'solid'
    ? [
        { percent: 0, color: color.value },
        { percent: 100, color: { r: 255, g: 255, b: 255, a: 1 } }
      ]
    : currentColor.value.color
);
const degree = computed(() => currentColor.value.degree || 90);

const stopPropagation = (e) => {
  e.stopPropagation();
};
const colorChangedCurrent = (color) => {
  const css = chain(color.css)
    .replace('background-image:', '')
    .replace('background-color:', '')
    .replace(';', '')
    .value();
  currentColor.value.color = color.color;
  currentColor.value.css = css;
  currentColor.value.mode = color.mode;
  currentColor.value.degree = color.degree;
};

const colorChanged = () => {
  const color = { ...currentColor.value };
  emit('update:modelValue', color);
  emit('update', color);
  emit('change', {
    target: {
      name: props.name,
      type: 'color-picker',
      value: color
    }
  });
};
const removeColor = () => {
  emit('update:modelValue', '');
  emit('update', '');
  emit('change', {
    target: {
      name: props.name,
      type: 'color-picker',
      value: ''
    }
  });

  currentColor.value.color = {};
  currentColor.value.css = undefined;
  currentColor.value.mode = '';
};
onMounted(() => {
  showColorPicker.value = true;
});
const clickColorPicker = () => {
  document
    .querySelector(`#${showColorId.value} .cbtn`)
    ?.dispatchEvent(new Event('click'));
};
</script>
<template>
  <label
    for="primaryColor"
    class="flex items-center color-picker"
    :class="{ 'color-selected': colorSelected, right: right }"
  >
    <p
      v-if="modelValue"
      class="text-3xl cursor-pointer"
      @click="clickColorPicker"
    >
      <span class="text-brand-color">{{ title ? title : 'Edit' }}</span>
    </p>
    <p
      v-else
      class="text-3xl text-brand-color cursor-pointer"
      @click="clickColorPicker"
      >{{ title ? title : 'Pick color' }}</p
    >
    <color-picker
      class="color-picker-container mx-4"
      :id="showColorId"
      :name="name"
      :mode="mode"
      :color="color"
      :degree="degree"
      :gradients="gradients"
      @colorChanged="colorChangedCurrent"
      @input="stopPropagation"
    >
      <template #after-active-mode>
        <div class="flex-1"></div>
        <span
          class="text-error-color py-2 pr-4 cursor-pointer text-3xl"
          @click="removeColor"
        >
          <i class="fa-regular fa-trash"></i>
        </span>
      </template>
      <template #in-hex-wrapper>
        <pm-button compact size="small" @click="colorChanged">Apply</pm-button>
      </template>
    </color-picker>
  </label>
</template>
<style>
.color-picker .hue-bar,
.color-picker .opacity-bar,
.color-picker .grad-bar {
  position: relative;
  height: 13px !important;
}

.color-picker .color-picker-container {
  position: relative;
}

.color-picker .hue-bar .picker,
.color-picker .opacity-bar .picker,
.color-picker .grad-bar .picker {
  top: 0px !important;
}

.color-picker.color-wheel {
  display: inline-block;
}

.color-picker .preview-wrapper .preview-color {
  width: 30px;
  height: 30px;
}

.color-picker .cbtn {
  border-width: 2px;
  border-radius: 100%;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  overflow: hidden;
}

.color-picker:not(.color-selected) .cbtn {
  background: v-bind(defaultCurrentColour);
  background-image: v-bind(defaultCurrentColour);
  background-size: contain;
}

.color-picker:not(.color-selected) .cbtn .cbtn-inner {
  display: none;
}

.color-picker .huebar-wrapper .bar-wrapper {
  height: 32px;
}

.color-picker .degree {
  position: relative;
}

.color-picker .degree .picker-deg {
  position: absolute;
}

.color-picker .cbtn-inner,
.color-picker .palette-wrapper,
.color-picker .hue-bar,
.color-picker .opacity-bar,
.color-picker .dropper {
  cursor: pointer;
}

.color-picker.right .panel {
  right: 0px;
}

.color-picker.right .panel::before {
  left: auto;
  right: 15px;
}

.color-picker.right .panel::after {
  left: auto;
  right: 15px;
}

.color-picker .hex-wrapper {
  font-size: 1.875rem;
  line-height: 2.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
  position: relative;
}

.color-picker .hex-wrapper .hex-wrapper-input {
  padding-left: 2.5rem;
  padding: 0.5rem;
  padding-left: 2.55rem;
  border-width: 1px;
  flex: 1 1 0%;
  width: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.8rem;
}

.color-picker .hex-wrapper .hex-wrapper-label {
  color: var(--brand-color);
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-right: 0.5rem;
}

.color-picker .hex-wrapper-icon::before {
  content: '\23';
  color: rgba(107, 114, 128);
}

.color-picker .hex-wrapper-icon {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  color: rgba(107, 114, 128);
  left: 5rem;
  margin-top: 1px;
  position: absolute;
  font-weight: 900;
}
</style>
