import axios from 'axios';

const BASE_URL = process.env.VUE_APP_STRAPI_URL;

export default function authService(jwt) {
  const signIn = async (email, password) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/auth/local`,
        data: {
          identifier: email,
          password: password
        }
      });
      if (response.data.success) {
        return {
          success: true,
          message: response.data
        };
      }
      return {
        success: false,
        message: response.data.message
      };
    } catch (err) {
      if (err.toString() === 'Error: Request failed with status code 400') {
        return {
          success: false,
          message: 'Incorrect email or password'
        };
      }
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const signOut = () =>
    axios({
      method: 'GET',
      url: `${BASE_URL}/auth/logout`,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });

  const generateOTP = async (email) => {
    // send to strapi, strapi creates otp in firebase and send email to user email address
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/generate-otp?email=${encodeURIComponent(
          email
        )}`
      });
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message
        };
      }
    } catch (err) {
      console.error('OTP:', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
    return {
      success: true,
      message: ''
    };
  };

  const generateLoginOTP = async (email) => {
    // send to strapi, strapi creates otp in firebase and send email to user email address
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/generate-login-otp?email=${encodeURIComponent(
          email
        )}`
      });
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message
        };
      }
    } catch (err) {
      console.error('OTP:', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
    return {
      success: true,
      message: ''
    };
  };

  const verify = async (email, otp) => {
    // send to strapi, strapi creates otp in firebase and send email to user email address
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/verify-otp?email=${encodeURIComponent(
          email
        )}&otp=${otp}`
      });
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message
        };
      }
      return {
        success: true,
        message: ''
      };
    } catch (err) {
      console.error('OTP:', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const link = async (email, otp, password) => {
    // send to strapi, strapi creates otp in firebase and send email to user email address
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/link?email=${encodeURIComponent(
          email
        )}&otp=${otp}${password ? `&password=${password}` : ''}`
      });
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message
        };
      }
      return {
        success: true,
        message: response.data.message // user object
      };
    } catch (err) {
      console.error('OTP:', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const resetPassword = async (password) => {
    // send to strapi, strapi creates otp in firebase and send email to user email address
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/setup`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json'
        },
        data: {
          password: password
        }
      });
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message
        };
      }
      return {
        success: true,
        message: response.data.message // user object
      };
    } catch (err) {
      console.error('Reset Password:', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const forgotPassword = async (email) => {
    try {
      const result = await axios({
        method: 'POST',
        url: `${BASE_URL}/auth/forgot-password`,
        data: {
          email: email
        }
      });
      if (result.data.success) {
        return {
          success: true,
          message: ''
        };
      }
      return {
        success: false,
        message: result.data.message
      };
    } catch (err) {
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const resetForgottenPassword = async (password, code) => {
    try {
      await axios.post(BASE_URL + '/auth/reset-password', {
        code, // code contained in the reset link
        password,
        passwordConfirmation: password
      });
      return {
        success: true,
        message: ''
      };
    } catch (err) {
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const validateJWT = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${BASE_URL}/jwts/verify`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json'
        }
      });
      return {
        success: true
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

  const getUser = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/companies/users/me`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json'
        }
      });
      return {
        success: true,
        message: response.data // user object
      };
    } catch (err) {
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  const getProfile = async (profileId) => {
    if (profileId) {
      try {
        const response = await axios({
          method: 'GET',
          url: `${BASE_URL}/profiles/${profileId}`,
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json'
          }
        });
        return {
          success: true,
          message: response.data // profile object
        };
      } catch (err) {
        console.error(err);
        return {
          success: false,
          message:
            err?.response?.data?.message?.[0].messages?.[0]?.message ||
            'Oops! Error'
        };
      }
    } else {
      return {
        success: true,
        message: null // profile object
      };
    }
  };

  const getUserFromToken = async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/auth/google/callback/?${token}`
      });
      return {
        success: true,
        message: response.data // user object
      };
    } catch (err) {
      console.error(err);
      return {
        success: false,
        message: 'Please sign in using your email and password'
      };
    }
  };

  const registerWithGoogle = async (email) => {
    try {
      const linkGoogleProfile = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/link?email=${encodeURIComponent(
          email
        )}&provider=google`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });

      if (linkGoogleProfile.data.success) {
        return {
          success: true,
          message: linkGoogleProfile.data.message // user object
        };
      }

      return {
        success: false,
        message: 'Account can not be linked to existing profile'
      };
    } catch (err) {
      console.error('google', err);
      return {
        success: false,
        message:
          err?.response?.data?.message?.[0].messages?.[0]?.message ||
          'Oops! Error'
      };
    }
  };

  return {
    forgotPassword,
    signIn,
    generateOTP,
    generateLoginOTP,
    verify,
    link,
    getProfile,
    resetPassword,
    resetForgottenPassword,
    getUser,
    validateJWT,
    signOut,
    registerWithGoogle,
    getUserFromToken
  };
}
