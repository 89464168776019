/* eslint-disable no-async-promise-executor */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { DateTime } from 'luxon';
import { userRole } from '~/functions/utils-functions';
import authService from '~/api/auth';

const logo =
  'https://res.cloudinary.com/profileme/image/upload/v1685360438/Profile_Me_Dark_343bb9810b.png';
const lightLogo =
  'https://res.cloudinary.com/profileme/image/upload/v1685360438/Profile_Me_099011a776.png';
const icon =
  'https://res.cloudinary.com/profileme/image/upload/v1685360438/profileme_logo_b438f3bb87.png';
const lightIcon =
  'https://res.cloudinary.com/profileme/image/upload/v1685360438/profileme_logo_white_4693c9ffda.png';
const profilePlaceholderImage =
  'https://res.cloudinary.com/profileme/image/upload/v1685360439/person_63a3dff040.jpg';

const auth = {
  namespaced: true,
  state: {
    /*
    {
      "id": number,
      "username": string,
      "email": string,
      "provider": string,
      "confirmed": boolean,
      "blocked": boolean,
      "role": {
        "id": number,
        "name": string,
        "description": string,
        "type": string
      },
      "token": string,
      "profile": number,
      "title": string,
      "login_attempts": number,
      "account_blocked_at": null,
      "companies": [
        {
          "title": string,
          "id": number,
          "masterProfiles": []
        }
      ]
    }
    title = "Discovery GC's"
     */
    user: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setProfileId(state, id) {
      state.user.profile = id;
    },
    login(state, user) {
      state.user = user || state.user;
    },
    logout(state) {
      state.user = null;
      if (window) {
        authService(sessionStorage.getItem('_token'))
          .signOut()
          .finally(() => {
            delete localStorage._token;
            delete sessionStorage._token;
          });
      }
    }
  }
};

const supervisor = {
  namespaced: true,
  state: {
    supervisorCompanies: [],
    filterByDivision: undefined,
    filterByProfile: undefined
  },
  mutations: {
    init(state, user) {
      if (userRole(user) === 'supervisor' || userRole(user) === 'superadmin') {
        state.supervisorCompanies = user.companies;
      }
    },
    clear(state) {
      state.supervisorCompanies = [];
    },
    setDivisionFilter(state, division) {
      state.filterByDivision = division;
    },
    setProfileFilter(state, profile) {
      state.filterByProfile = profile;
    }
  }
};

const formatCss = (whiteLabel) => {
  const getColorVar = (varName, color) => {
    if (color) return `${varName}: ${color};`;
    return '';
  };
  return `
    :root {
      ${getColorVar('--brand-color', whiteLabel.brand_color)}
      ${getColorVar('--brand-dark-color', whiteLabel.brand_dark_color)}
      ${getColorVar('--brand-color-success', whiteLabel.brand_color_success)}
      ${getColorVar('--brand-color-error', whiteLabel.brand_color_error)}
    }

    ${whiteLabel.css}
  `;
};

const whiteLabelState = () => ({
  hasWhiteLabel: false,
  css: '',
  logo,
  lightLogo,
  icon,
  profilePlaceholderImage,
  lightIcon,
  date: 0,
  brandColor: '',
  brandDarkColor: '',
  brandColorSuccess: '',
  profileDomain: '',
  brandColorError: '',
  title: 'ProfileMe',
  video: ''
});
const whiteLabel = {
  namespaced: true,
  state: whiteLabelState(),
  mutations: {
    setWhiteLabel(state, _inWhiteLabel) {
      state.hasWhiteLabel = !!_inWhiteLabel;
      const inWhiteLabel = _inWhiteLabel || whiteLabelState();
      state.date = DateTime.fromISO(inWhiteLabel.updatedAt).toMillis();
      state.domain = inWhiteLabel.domain;
      state.profileDomain = inWhiteLabel.profile_domain;
      state.css = formatCss(inWhiteLabel);
      state.logo = inWhiteLabel?.logo?.url || logo;
      state.icon = inWhiteLabel?.icon?.url || icon;
      state.profilePlaceholderImage =
        inWhiteLabel?.profilePlaceholderImage?.url ||
        state.profilePlaceholderImage;
      state.brandColor = inWhiteLabel?.brand_color;
      state.brandDarkColor = inWhiteLabel?.brand_dark_color;
      state.brandColorSuccess = inWhiteLabel?.brand_color_success;
      state.brandColorError = inWhiteLabel?.brand_color_error;
      state.lightLogo =
        inWhiteLabel?.light_Logo?.url || inWhiteLabel?.logo?.url || lightLogo;
      state.lightIcon =
        inWhiteLabel?.light_icon?.url || inWhiteLabel?.icon?.url || lightIcon;
      state.title = inWhiteLabel.title || state.title;
      state.video = inWhiteLabel.tut_video;
    }
  }
};

const store = createStore({
  plugins: [
    createPersistedState({
      paths: ['auth', 'supervisor', 'whiteLabel']
    })
  ],
  modules: {
    auth,
    supervisor,
    whiteLabel
  },
  state: {
    styling: {}
  }
});

export default store;
